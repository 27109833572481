import React, {Component} from 'react';
import Path from "./Path";
import Segment from "./Segment";
import {MdLocationPin} from "react-icons/md";
import {roundDistance} from "./RouteInformation";
import MarkerContext from "./MarkerContext";
import Coordinate from "./Coordinate";
import {
    TbArrowBearLeft,
    TbArrowBearRight, TbArrowDown, TbArrowDownLeft, TbArrowDownRight, TbArrowLeft, TbArrowRight,
    TbArrowSharpTurnLeft,
    TbArrowSharpTurnRight, TbArrowUp, TbArrowUpLeft, TbArrowUpRight, TbCornerUpLeft,
    TbCornerUpRight
} from "react-icons/tb";
import {MUSIC_BUILDING, RICK_ASTLEY_LYRICS} from "./Constants";

/**
 * Calculates the angle of the given segment from north.
 * @param seg Segment to calculate angle of.
 * @return Angle in degrees, range [0, 360)
 */
function calculateAngleFromNorth(seg: Segment): number {
    let dy = seg.end.y - seg.start.y;
    let dx = seg.end.x - seg.start.x;
    let theta = Math.atan2(dx, -dy); // flipped arguments for angle from y-axis, range (-PI, PI]
    theta = theta * (180 / Math.PI); // rads to degs, range (-180, 180]

    // Change from (-180, 180] to [0, 360)
    if (theta < 0) {
        theta = 360 + theta;
    }

    return theta;
}

/**
 * Returns a string representing the cardinal/ordinal direction of the given segment.
 * @param seg Segment to calculate angle of.
 * @return One of "N", "NE", "E", "SE", "S", "SW", "W", "NW"
 */
function getDirection(seg: Segment): string {
    let theta = calculateAngleFromNorth(seg);

    // return theta.toString();
    if (theta > 0 && theta <= 22) {
        return "N";
    }
    if (theta > 22 && theta <= 67) {
        return "NE";
    }
    if (theta > 68 && theta <= 112) {
        return "E";
    }
    if (theta > 112 && theta <= 157) {
        return "SE";
    }
    if (theta > 157 && theta <= 202) {
        return "S";
    }
    if (theta > 202 && theta <= 247) {
        return "SW";
    }
    if (theta > 247 && theta <= 292) {
        return "W";
    }
    if (theta > 292 && theta <= 337) {
        return "NW";
    }
    return "N";
}

/**
 * Returns a react-icon element corresponding to the closest cardinal/ordinal direction to the given angle.
 * @param theta Angle from north.
 * @return An arrow icon corresponding to one of "0deg", "45deg", "90deg", "135deg", "180deg", "225deg", "270deg", "315deg"
 */
function angleToDirectionIcon(theta: number): JSX.Element {
    if (theta > 0 && theta <= 22) {
        return <TbArrowUp/>;
    }
    if (theta > 22 && theta <= 67) {
        return <TbArrowUpRight/>;
    }
    if (theta > 68 && theta <= 112) {
        return <TbArrowRight/>;
    }
    if (theta > 112 && theta <= 157) {
        return <TbArrowDownRight/>;
    }
    if (theta > 157 && theta <= 202) {
        return <TbArrowDown/>;
    }
    if (theta > 202 && theta <= 247) {
        return <TbArrowDownLeft/>;
    }
    if (theta > 247 && theta <= 292) {
        return <TbArrowLeft/>;
    }
    if (theta > 292 && theta <= 337) {
        return <TbArrowUpLeft/>;
    }
    return <TbArrowUp/>;
}

/**
 * Returns a string describing the turn direction of an angle between two path segments.
 * Has the same selection logic as angleToTurnIcon().
 * @param theta The angle between the two path segments.
 * @returns One of "sharp right", "right", "slight right", "slight left", "left", "sharp left"
 */
function angleToTurnDirection(theta: number): string {
    let returnString = "";

    if (Math.abs(theta) < 60) {
        returnString += "slight ";
    } else if (Math.abs(theta) > 120) {
        returnString += "sharp ";
    }

    if (theta < 0) {
        returnString += "right";
    } else {
        returnString += "left";
    }

    return returnString;
}

/**
 * Returns a react-icon element corresponding to the given angle between two path segments.
 * Has the same selection logic as angleToTurnDirection().
 * @param theta The angle between the two path segments.
 * @returns An icon that corresponds to one of "sharp right", "right", "slight right", "slight left", "left", "sharp left"
 */
function angleToTurnIcon(theta: number): JSX.Element {
    if (theta < 0) {
        if (theta > -60) {
            return <TbArrowBearRight />
        } else if (theta < -120) {
            return <TbArrowSharpTurnRight />
        } else {
            return <TbCornerUpRight />
        }
    } else {
        if (theta < 60) {
            return <TbArrowBearLeft/>
        } else if (theta > 120) {
            return <TbArrowSharpTurnLeft />
        } else {
            return <TbCornerUpLeft />
        }
    }
}

/**
 * Calculates the angle between three coordinates.
 * @param a First coordinate
 * @param b Middle coordinate
 * @param c Third coordinate
 * @return The angle between the coordinates in degrees.
 */
function find_angle(a: Coordinate, b: Coordinate, c: Coordinate) {
    // let AB: Coordinate = {x:B.x-A.x, y:B.y-A.y}
    // let AC: Coordinate = {x:C.x-A.x, y:C.y-A.y}
    //
    // let angleRadians = Math.atan2( AB.x*AC.x + AB.y*AC.y, AB.y*AC.x - AB.x*AC.y )
    //
    // return angleRadians*(180/Math.PI)
    let crossProduct = (c.x - b.x) * (b.y - a.y) - (c.y - b.y) * (b.x - a.x)
    let dotProduct = (c.x - b.x) * (b.x - a.x) + (c.y - b.y) * (b.y - a.y)
    return Math.atan2(crossProduct, dotProduct) * (180 / Math.PI)
}

interface TurnByTurnProps {
    path: Path
    startBuilding: string
    endBuilding: string
}

/**
 * Displays turn-by-turn directions calculated based on the angle between path segments.
 */
class TurnByTurn extends Component<TurnByTurnProps, {}> {
    static contextType = MarkerContext

    componentDidMount() {
        const turnCoords: Coordinate[] = []

        if (this.props.path.path.length > 0) {
            for (let i = 0; i < this.props.path.path.length - 1; i++) {
                // let deltaAngle = calculateAngleFromNorth(this.props.path.path[i+1]) - calculateAngleFromNorth(this.props.path.path[i]);
                let deltaAngle = find_angle(this.props.path.path[i].start, this.props.path.path[i].end, this.props.path.path[i + 1].end)
                if (Math.abs(deltaAngle) > 30) {
                    turnCoords.push(this.props.path.path[i].end)
                }
            }
        }

        this.context.setCoords(turnCoords);
    }

    componentWillUnmount() {
        this.context.setCoords([]);
    }

    render() {
        const directions = []

        if (this.props.startBuilding === MUSIC_BUILDING && this.props.endBuilding === MUSIC_BUILDING) {
            const lines = RICK_ASTLEY_LYRICS.split("\n");

            lines.forEach((value,index) => {
                directions.push(
                    <div className={"direction-entry"} key={index}>
                        <h1>
                            {value.split("|")[0]}
                        </h1>
                        <h2>
                            {value.split("|")[1]}
                        </h2>
                    </div>
                )
            });
        } else if (this.props.path.path.length > 0) {
            directions.push(
                <div className={"direction-entry"}>
                    {angleToDirectionIcon(calculateAngleFromNorth(this.props.path.path[0]))}
                    <h3>
                        Head {getDirection(this.props.path.path[0])} from {this.props.startBuilding}
                    </h3>
                </div>
            )

            let deltaDistance = 0;
            for (let i = 0; i < this.props.path.path.length - 1; i++) {
                deltaDistance += this.props.path.path[i].cost;
                let deltaAngle = find_angle(this.props.path.path[i].start, this.props.path.path[i].end, this.props.path.path[i + 1].end)
                if (Math.abs(deltaAngle) > 30) {
                    directions.push(
                        <div className={"direction-entry"}>
                            {angleToTurnIcon(deltaAngle)}
                            <h3>
                                 In {roundDistance(deltaDistance)}, turn {angleToTurnDirection(deltaAngle)}
                            </h3>
                        </div>

                    )
                    deltaDistance = 0;
                }
            }

            directions.push(
                <div className={"direction-entry"}>
                    <MdLocationPin/>
                    <h3>
                        In {roundDistance(this.props.path.path[this.props.path.path.length-1].cost)}, you will arrive at <b>{this.props.endBuilding}</b>
                    </h3>
                </div>
            )
        }

        return (
            <div className={"turn-by-turn"}>
                <hr className="solid"/>
                <div className={"directions"}>
                    {
                        directions.map((value, index) => (
                            <h4 key={index}>{value}</h4>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default TurnByTurn;