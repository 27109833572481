/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2022 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

import {DomEvent, LatLngExpression} from "leaflet";
import React, {Component, useEffect, useRef} from "react";
import {MapContainer, TileLayer, useMap} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {UW_LATITUDE_CENTER, UW_LONGITUDE_CENTER} from "./Constants";
import Edge from "./Edge";
import MapLine from "./MapLine";
import MapMarker from "./MapMarker";

// This defines the location of the map. These are the coordinates of the UW Seattle campus
const position: LatLngExpression = [UW_LATITUDE_CENTER, UW_LONGITUDE_CENTER];

interface MapProps {
    // DONE: Define the props of this component. You will want to pass down edges
    // so you can render them here
    edgeList: Array<Edge>
}

interface MapState {
    zoom: number;
}


/**
 * Displays the map, path, and markers.
 * Resets the map zoom to its initial state when the provided path is empty.
 */
class Map extends Component<MapProps, MapState> {
    constructor(props: any) {
        super(props);
        this.state = {
            zoom: 15
        }
    }

    render() {
        return (
            <div className={"map"}>
                <MapContainer
                    center={position}
                    zoom={16}
                    minZoom={15}
                    scrollWheelZoom={true}
                    zoomControl={false}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {/*<ZoomControl position="topright" />*/}
                    {
                        // DONE: Render map lines here using the MapLine component. E.g.
                        // <MapLine key={key1} color="red" x1={1000} y1={1000} x2={2000} y2={2000}/>
                        // will draw a red line from the point 1000,1000 to 2000,2000 on the
                        // map
                        //   this.props.edgeList.map((value, index) => (
                        //       <MapLine key={index} color={value.color} x1={value.x1} y1={value.y1} x2={value.x2} y2={value.y2}/>
                        //   ))

                    }
                    {this.props.edgeList.length > 0 ? <MapLine color={"var(--path-color)"} edgeList={this.props.edgeList} /> : <MapResetZoom />}
                    <MapMarker />
                    <MapSetZoom />
                </MapContainer>

            </div>
        );
    }
}

/**
 * Resets the map position to its initial state when called.
 */
function MapResetZoom() {
    const map = useMap();
    map.flyTo(position, 16,{duration: 0.5});
    return null;
}

/**
 * Displays buttons to zoom the map in and out.
 * These custom buttons are meant to replace the default ones provided by react-leaflet.
 */
const MapSetZoom: React.FC = () => {
    const parentMap = useMap();

    const divContainer = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (divContainer.current) {
            DomEvent.disableClickPropagation(divContainer.current);
            DomEvent.disableScrollPropagation(divContainer.current);
        }
    });

    return (
        <div className={"map-controls"} ref={divContainer}>
            <button onClick={() => parentMap.zoomIn()}>
                +
            </button>
            <button onClick={() => parentMap.zoomOut()}>
                -
            </button>
        </div>
    )
}


export default Map;
