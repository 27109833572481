import React, {Component} from 'react';
import Select, {ActionMeta} from 'react-select';
import {OptionType} from "./OptionType";
import ThemeContext from "./ThemeProvider";
import {MdRestartAlt, MdDarkMode, MdOutlineDarkMode} from "react-icons/md";

interface DestinationInputProps {
    onChange(startBuilding: string, endBuilding: string, startBuildingName: string, destinationBuildingName: string): void;
    onClear(): void; // Called when reset button is clicked
    buildingOptions: Array<OptionType>
}

interface DestinationInputState {
    startBuildingOption: OptionType | null // OptionType is the type used by react-select for the dropdowns
    endBuildingOption: OptionType | null
    startBuildingError: boolean
    endBuildingError: boolean
}

/**
 * Displays the dropdowns that allow the user to select a start and destination building.
 * Also provides dark mode and reset buttons.
 * On change, returns the short codes and full names of the user-selected start and destination building.
 */
class DestinationInput extends Component<DestinationInputProps, DestinationInputState> {
    constructor(props: any) {
        super(props);
        this.state = {
            startBuildingOption: null,
            endBuildingOption: null,
            startBuildingError: false,
            endBuildingError: false
        };
    }

    // Calls the parent's onClear when a field is cleared by the user
    onInputChange = (
        option: OptionType | null,
        action: ActionMeta<OptionType>
    ) => {
        this.props.onClear();
        // if (action.action === 'clear') {
        //     this.props.onClear();
        // }
        return option;
    };

    handleSubmit(): void {
        // Highlights fields in red if they are empty when submit is called
        // this.setState({startBuildingError: this.state.startBuildingOption === null});
        // this.setState({endBuildingError: this.state.endBuildingOption === null});

        // Prevents submission unless both fields are populated
        if (this.state.startBuildingOption !== null && this.state.endBuildingOption !== null) {
            this.props.onChange(this.state.startBuildingOption.value,
                this.state.endBuildingOption.value,
                this.state.startBuildingOption.label,
                this.state.endBuildingOption.label
                );
        }
    };

    // Calls the parent's onClear and clears our two fields
    handleClear(): void {
        this.props.onClear();
        this.setState({startBuildingOption: null, endBuildingOption: null});
    }


    render() {
        const customStyles = {
            control: (provided: Record<string, unknown>) => ({
                ...provided,
                color: "var(--text-color)",
                borderColor: "var(--dropdown-border)",
                backgroundColor: "var(--dropdown-background)",
                transition: "background-color 0.1s"
            }),
            menu: (provided: Record<string, unknown>) => ({
                ...provided,
                borderColor: "var(--dropdown-border)",
                backgroundColor: "var(--dropdown-background)"
            }),
            option: (provided: Record<string, unknown>) => ({
                ...provided,
                backgroundColor: 'transparent',
                color: "var(--text-color)",
                '&:hover': {
                    backgroundColor: "var(--option-highlight)"
                },
            }),
            singleValue: (provided: Record<string, unknown>) => ({
                ...provided,
                color: "var(--text-color)",
            }),
            input: (provided: Record<string, unknown>) => ({
                ...provided,
                color: "var(--text-color)",
            }),
        };


        return (
            <div className={"dropdown-outer-container"}>
                <div className={"dropdown-container"}>
                    <Select value={this.state.startBuildingOption} styles={customStyles} options={this.props.buildingOptions} isClearable={true} isLoading={this.props.buildingOptions.length === 0}
                            placeholder={"Enter starting point"} onChange={(option, action) => this.setState({startBuildingOption: this.onInputChange(option, action)}, () => {this.handleSubmit()})}/>
                    <Select value={this.state.endBuildingOption} styles={customStyles} options={this.props.buildingOptions} isClearable={true} isLoading={this.props.buildingOptions.length === 0}
                            placeholder={"Enter destination"} onChange={(option, action) => this.setState({endBuildingOption: this.onInputChange(option, action)}, () => {this.handleSubmit()})}/>
                </div>
                {/*<button onClick={() => this.handleSubmit()}>Make request</button>*/}
                <div className={"button-container"}>
                    <button id={"top"} title={"Reset"} onClick={() => this.handleClear()}><span><MdRestartAlt /></span></button>
                    <ThemeContext.Consumer>
                        {({dark, toggle}) => (
                            <button id={"bottom"} title={"Toggle Dark Mode"}
                                    onClick={toggle} >
                                <span>{dark ? <MdDarkMode /> : <MdOutlineDarkMode/>}</span>
                            </button>
                        )}
                    </ThemeContext.Consumer>
                </div>
            </div>
        );
    }
}
export default DestinationInput;