/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2022 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

import React, {Component} from "react";
import {CircleMarker, Marker} from "react-leaflet";

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import MarkerContext from "./MarkerContext";
import MemeMarkerContext from "./MemeMarkerContext";
import {xToLon, yToLat} from "./MapLine";

// Code below fixes leaflet marker icon not appearing
// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

// Easter egg
const rickAstleyIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + "rickastley.gif",
    iconSize: [100, 100]
});

/**
 * Displays markers provided by MarkerContext and MemeMarkerContext on the map.
 */
class MapMarker extends Component<{}, {}> {
    render() {
        return (
            <span>
                <MarkerContext.Consumer>
                    {({coords}) => (
                        coords.map((coord, index) => {
                            // @ts-ignore
                            if (coord !== undefined) {
                                return <CircleMarker key={index} center={[yToLat(coord.y), xToLon(coord.x)]}
                                                     radius={2} pathOptions={{color: "white", fillColor: "white", fillOpacity: 1}}/>
                            } else {
                                return null;
                            }
                        })
                    )}
                </MarkerContext.Consumer>
                <MemeMarkerContext.Consumer>
                     {({coords}) => (
                         coords.map((coord, index) => {
                             // @ts-ignore
                             if (coord !== undefined) {
                                 return <Marker key={index} position={[yToLat(coord.y), xToLon(coord.x)]}
                                                      icon={rickAstleyIcon}/>
                             } else {
                                 return null;
                             }
                         })
                     )}
                </MemeMarkerContext.Consumer>
            </span>
        );
    }
}

export default MapMarker;
