/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2022 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

export const SERVER_URL : string = "https://ia5mgzl73jft4i62ti7xeaawdm0alytq.lambda-url.us-west-2.on.aws";
// export const SERVER_URL: string = "http://localhost:4567";

// Latitude of the UW Seattle campus
export const UW_LATITUDE : number = 47.65878405511131;

// Offset to translate coordinate system
export const UW_LATITUDE_OFFSET : number = 807.35188;

// Scale to translate coordinate system
export const UW_LATITUDE_SCALE : number = -0.00000576766;

// Longitude of the UW Seattle campus
export const UW_LONGITUDE : number = -122.31305164734569;

// Offset to translate coordinate system
export const UW_LONGITUDE_OFFSET : number = 1370.6408;

// Scale to translate coordinate system
export const UW_LONGITUDE_SCALE : number = 0.00000848028;

// Map center
export const UW_LATITUDE_CENTER = 47.65440627742146;

// Map center
export const UW_LONGITUDE_CENTER = -122.30476957834502;

// Easter egg
export const MUSIC_BUILDING = "Music Building (South Entrance)"
export const RICK_ASTLEY_LYRICS =
    "💘|We're no strangers to love\n" +
    "📖|You know the rules and so do I (do I)\n" +
    "🤝|A full commitment's what I'm thinking of\n" +
    "🙅‍♂️|You wouldn't get this from any other guy\n" +
    "😩|I just wanna tell you how I'm feeling\n" +
    "🤯|Gotta make you understand\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n" +
    "🫂|We've known each other for so long\n" +
    "💓|Your heart's been aching, but you're too shy to say it (say it)\n" +
    "🚪|Inside, we both know what's been going on (going on)\n" +
    "🎮|We know the game and we're gonna play it\n" +
    "🤔|And if you ask me how I'm feeling\n" +
    "👀|Don't tell me you're too blind to see\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n" +
    "🫂|We've known each other for so long\n" +
    "💓|Your heart's been aching, but you're too shy to say it (say it)\n" +
    "🚪|Inside, we both know what's been going on (going on)\n" +
    "🎮|We know the game and we're gonna play it\n" +
    "😩|I just wanna tell you how I'm feeling\n" +
    "🤯|Gotta make you understand\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n" +
    "⬆️|Never gonna give you up\n" +
    "⬇️|Never gonna let you down\n" +
    "🏃‍♂️|Never gonna run around and desert you\n" +
    "😭|Never gonna make you cry\n" +
    "👋|Never gonna say goodbye\n" +
    "🗣️|Never gonna tell a lie and hurt you\n"