import React, {Component} from 'react';
import Path from "./Path";
import {MdDirectionsWalk} from "react-icons/md";
import TurnByTurn from "./TurnByTurn";
import {MUSIC_BUILDING} from "./Constants";

interface RouteInformationProps {
    path: Path
    startBuilding: string
    endBuilding: string
}

function roundDistance(feet: number): string {
    if (feet < 580) {
        return Math.round(feet) + " ft";
    } else {
        return Math.round(feet / 58) / 100 + " mi";
    }
}

/**
 * Displays modal with path walking distance, time, and turn-by-turn directions.
 */
class RouteInformation extends Component<RouteInformationProps, {}> {
    render() {
        const isMeme = this.props.startBuilding === MUSIC_BUILDING &&
            this.props.endBuilding === MUSIC_BUILDING

        return (
            <div className={"route-information-container"}>
                <div className={"route-information"}>
                    <h2><MdDirectionsWalk/>{isMeme ? 69 : Math.round(this.props.path.cost / 264)} min</h2>

                    <p>{isMeme ? "420 mi" : roundDistance(this.props.path.cost)}</p>

                </div>
                {(this.props.path.path.length > 0 || isMeme)
                    && <TurnByTurn path={this.props.path}
                                   startBuilding={this.props.startBuilding}
                                   endBuilding={this.props.endBuilding}/>}
            </div>

        );
    }
}

export {roundDistance};
export default RouteInformation;