/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2022 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

// Allows us to write CSS styles inside App.css, any styles will apply to all components inside <App />
import "./App.css";

import React, {Component} from 'react';
import LeafletMap from "./Map";
import Edge from "./Edge";
import Path from "./Path";
import DestinationInput from "./DestinationInput";
import {OptionType} from "./OptionType";
import {ThemeProvider} from "./ThemeProvider";
import RouteInformation from "./RouteInformation";
import {MarkerProvider} from "./MarkerContext";
import Sound from "./Sound";
import {MUSIC_BUILDING, SERVER_URL} from "./Constants";
import {MemeMarkerProvider} from "./MemeMarkerContext";

interface AppState {
    edgeList: Array<Edge>
    buildingOptions: Array<OptionType>
    errors: Array<string>
    path: Path | null
    startingPointName: string
    destinationName: string
}

class App extends Component<{}, AppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            edgeList: [],
            buildingOptions: [],
            errors: [],
            path: null,
            startingPointName: "",
            destinationName: "",
        };
    }

    // Gets list of buildings from server on app start
    async componentDidMount() {
        await this.makeGetBuildingsRequest();
    }

    // Adds user-selected startingPointName and destinationName to this.state, then makes path request to server
    handleDestinationInput = (start: string, end: string, startBuildingName: string, destinationBuildingName: string) => {
        this.setState({startingPointName: startBuildingName, destinationName: destinationBuildingName});
        this.makeGetPathRequest(start, end);
    }

    render() {
        return (
            <ThemeProvider>
                <MarkerProvider>
                    <MemeMarkerProvider>
                        <div>
                            <div>
                                <LeafletMap edgeList={this.state.edgeList}/>
                            </div>
                            <div className={"destination-input"}>
                                <DestinationInput onChange={this.handleDestinationInput}
                                                  onClear={() => this.setState({
                                                      edgeList: [],
                                                      path: null,
                                                      startingPointName: "",
                                                      destinationName: ""
                                                  })}
                                                  buildingOptions={this.state.buildingOptions}/>
                                <div id="errors">
                                    {this.state.errors.map((value) => (
                                        <p key={value}> {value} </p>
                                    ))}
                                </div>
                            </div>
                            {
                                this.state.path !== null &&
                                <RouteInformation path={this.state.path} startBuilding={this.state.startingPointName}
                                                  endBuilding={this.state.destinationName}/>
                            }
                            {this.state.startingPointName === MUSIC_BUILDING &&
                                this.state.destinationName === MUSIC_BUILDING &&
                                <Sound/>}
                        </div>
                    </MemeMarkerProvider>
                </MarkerProvider>
            </ThemeProvider>

        );
    }

    // Makes request to server to find the shortest path between start and end buildings, and adds the returned path and
    // edgeList to this.state
    makeGetPathRequest = async (start: string, end: string) => {
        let newEdgeList: Array<Edge> = [];
        let newErrors: Array<string> = [];

        try {
            let response = await fetch(SERVER_URL + `/getPath?start=${start}&end=${end}`);
            if (!response.ok) {
                newErrors.push("Server reported an error - expected: 200, was: " + response.status + ". Try reloading the page.");
                this.setState({errors: newErrors});
                return;
            }

            const path: Path = await response.json() as Path;
            path.path.forEach(function (value) {
                newEdgeList.push({
                    color: "var(--path-color)",
                    x1: value.start.x,
                    y1: value.start.y,
                    x2: value.end.x,
                    y2: value.end.y
                });
            })
            this.setState({edgeList: newEdgeList, path: path});
        } catch (e) {
            console.error(e);
            newErrors.push("Could not connect to server - Please check your internet connection");
        }
        this.setState({errors: newErrors});
    }

    // Makes request to server to retrieve the list of buildings available for pathfinding.
    makeGetBuildingsRequest = async () => {
        let newErrors: Array<string> = [];
        let newBuildingOptions: Array<OptionType> = [];

        try {
            let response = await fetch(SERVER_URL + `/getBuildings`);
            if (!response.ok) {
                newErrors.push("Server reported an error - expected: 200, was: " + response.status + ". Try reloading the page.");
                this.setState({errors: newErrors});
                return;
            }

            const buildings: Map<string, string> = new Map(Object.entries(await response.json()));

            buildings.forEach((value, key) => {
                newBuildingOptions.push({label: value, value: key});
            })

            this.setState({buildingOptions: newBuildingOptions});
        } catch (e) {
            console.error(e);
            newErrors.push("Could not connect to server - Please check your internet connection");
        }
        this.setState({errors: newErrors});
    }
}

export default App;
