import React, {useLayoutEffect, useState} from "react";

const ThemeContext = React.createContext({
    dark: false, // True when dark theme is active
    toggle: () => {}, // Toggles light/dark theme
});

export default ThemeContext;

/**
 * Context that provides light/dark theme information and change method to child components.
 * Defaults to user browser default using CSS prefers-color-scheme.
 * @param children child components inside this context.
 */
// @ts-ignore
export function ThemeProvider({ children }) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [dark, setDark] = useState(prefersDark);

    useLayoutEffect(() => {
        if (dark) {
            document.documentElement.setAttribute('data-theme', 'dark');
        } else {
            document.documentElement.setAttribute('data-theme', 'light');
        }
    }, [dark]);

    const toggle = () => {
        setDark(!dark);
    };

    return (
        <ThemeContext.Provider
            value={{
                dark,
                toggle,
            }}>
            {children}
        </ThemeContext.Provider>
    );
}