import React, {useState} from "react";
import Coordinate from "./Coordinate";

// noinspection JSUnusedLocalSymbols
const MemeMarkerContext = React.createContext({
    coords: Array<Coordinate>(), // Array of marker coordinates
    setCoords: (coords: Coordinate[]) => {}, // Method that overwrites the array of marker coordinates
});

export default MemeMarkerContext;

/**
 * Context that allows child components to add meme markers to the map.
 * @param children child components inside this context.
 */
// @ts-ignore
export function MemeMarkerProvider({ children }) {
    const [coords, setCoordsState] = useState([...Array<Coordinate>(2)]);

    const setCoords = (coords: Coordinate[]) => {
        setCoordsState(coords);
    };

    return (
        <MemeMarkerContext.Provider
            value={{
                coords,
                setCoords,
            }}>
            {children}
        </MemeMarkerContext.Provider>
    );
}