import {useContext, useEffect} from "react";
import MemeMarkerContext from "./MemeMarkerContext";
import Coordinate from "./Coordinate";

/**
 * Plays rickastley.mp3 and adds meme markers to the map when mounted.
 * Stops playback and removes markers when unmounted.
 */
function Sound() {
    const audio = new Audio(process.env.PUBLIC_URL + "rickastley.mp3");
    const memeContext = useContext(MemeMarkerContext);

    useEffect(() => {
        audio.play()
        let coords: Coordinate[] = []
        for (let i = 0; i < 69; i++) {
            coords.push({x: Math.random() * 4000, y: Math.random() * 4000})

        }
        memeContext.setCoords(coords)

        // const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
        //     let coords: Coordinate[] = memeContext.coords
        //     coords.push({x: Math.random() * 4000, y: Math.random() * 4000})
        //     memeContext.setCoords(coords)
        //     console.log(memeContext.coords)
        // }, 5000)

        return () => {
            // clearInterval(intervalId)
            memeContext.setCoords([])
            audio.pause()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <span />
    );
}

export default Sound;