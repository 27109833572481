/*
 * Copyright (C) 2022 Soham Pardeshi.  All rights reserved.  Permission is
 * hereby granted to students registered for University of Washington
 * CSE 331 for use solely during Summer Quarter 2022 for purposes of
 * the course.  No other use, copying, distribution, or modification
 * is permitted without prior written consent. Copyrights for
 * third-party components of this work must be honored.  Instructors
 * interested in reusing these course materials should contact the
 * author.
 */

import React, {Component} from "react";
import {CircleMarker, Marker, Polyline, useMap} from "react-leaflet";
import {
    UW_LATITUDE,
    UW_LATITUDE_OFFSET,
    UW_LATITUDE_SCALE,
    UW_LONGITUDE,
    UW_LONGITUDE_OFFSET,
    UW_LONGITUDE_SCALE
} from "./Constants";
import Edge from "./Edge";

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// @ts-ignore
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});


interface MapLineProps {
    color: string; // color of line
    edgeList: Array<Edge>
}

/**
 * Converts x coordinate to longitude
 */
function xToLon(x: number): number {
    return UW_LONGITUDE + (x - UW_LONGITUDE_OFFSET) * UW_LONGITUDE_SCALE;
}

/**
 * Converts y coordinate to latitude
 */
function yToLat(y: number): number {
    return UW_LATITUDE + (y - UW_LATITUDE_OFFSET) * UW_LATITUDE_SCALE;
}

/**
 * A component that will render a line on the React Leaflet map of color from
 * point x1,y1 to x2,y2. This line will convert from the assignment's coordinate
 * system (where 0,0 is the top-left of the UW campus) to latitude and
 * longitude, which the React Leaflet map uses
 *
 * Also draws a marker at the start and destination buildings.
 */
class MapLine extends Component<MapLineProps, {}> {
    render() {
        const latLongs: [number, number][] = [];

        // [yToLat(this.props.y1), xToLon(this.props.x1)]
        this.props.edgeList.forEach((edge) => {
            latLongs.push([yToLat(edge.y1), xToLon(edge.x1)]);
            latLongs.push([yToLat(edge.y2), xToLon(edge.x2)]);
        })

        return (
            <span>
                <Polyline
                    className={"stroke-polyline"}
                    // Path options includes color, among a variety of line customizations
                    pathOptions={{color: this.props.color, weight: 6, lineCap: "round", lineJoin: "round", opacity: 1.0}}
                    // Positions are a list of latitude,longitude pairs that consist of the
                    // points on the line we draw on the map
                    positions={latLongs}
                >
                    <MapLineZoom latLongs={latLongs} />
                </Polyline>
                <CircleMarker className={"starting-point-marker"} center={latLongs[0]} radius={8} pathOptions={{color: "white", weight: 4, fillColor: "var(--path-color)", fillOpacity: 1}} />
                <Marker position={latLongs[latLongs.length -1]} />
            </span>
        );
    }
}

/**
 * Zooms the map to fit the provided path when called.
 * @param props latLongs: the array of lat and long pairs that make up the path to zoom to.
 */
function MapLineZoom(props: {latLongs: [number, number][]}) {
    const map = useMap();
    map.flyToBounds(props.latLongs, {duration: 1, maxZoom: 17, easeLinearity: 0.01, paddingTopLeft: [450, 1]});
    return null;
}

export default MapLine;
export {yToLat, xToLon};
